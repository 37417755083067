import iconDan from './icon_dan.svg'
import IconArrowRightUp from './icon_arrow_right_up.svg'
import IconArrowRight from './icon_arrow_right.svg'
import IconMetamask from './icon_metamask.svg'

const images = {
  iconDan,
  IconArrowRightUp,
  IconArrowRight,
  IconMetamask,
}

export default images
